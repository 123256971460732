<script>
import DropFile from "@/components/helexium/DropFile.vue";
import { mapGetters } from 'vuex'
export default {
  components: {
    DropFile
  },
  props: {
    vehiculo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isVisibleNuevoRegistro: false,
      isVisibleHistorial: false,
      historial: [],
      frontal: [],
      lateral: [],
      trasera: []
    }
  },
  async mounted() {
    await this.getImagenes()

    if (this.historial.length == 0) {
      this.isVisibleNuevoRegistro = true
    } else {
      this.isVisibleHistorial = true
    }

  },
  computed: {
    ...mapGetters(['useMoment']),
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    },
    vehiculoImagenInjection() {
      return {
        vehiculoId: this.vehiculo.id
      };
    }
  },
  methods: {
    async getImagenes() {
      const response = await this.$store.getters.fetchGet({ path: `vehiculo/ImagenesVehiculo/${this.vehiculo.id}` })
      const data = await response.json()
      this.historial = data.sort((a, b) => {
        return new Date(b.fechaDeCreacion) - new Date(a.fechaDeCreacion)
      })

      this.frontal = this.historial.filter(item => item.descripcion.includes('frontal'))
      this.lateral = this.historial.filter(item => item.descripcion.includes('Lateral'))
      this.trasera = this.historial.filter(item => item.descripcion.includes('Trasera'))
    }
  }
}
</script>

<template>
  <div>
    <div>
      <div @click="isVisibleNuevoRegistro = !isVisibleNuevoRegistro" class="pointer">
        <h3 class="py-2 btn btn-outline-success px-5 my-1">Nuevo registro</h3>
      </div>
      <b-collapse id="nuevoRegistro" v-model="isVisibleNuevoRegistro">
        <div
          style="display: grid; grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr)); gap: 10px; text-align: center; margin: auto">
          <div class="figure-zone">
            <h4 class="mt-3 mb-2">Delantero</h4>
            <DropFile :urlDir="`empresa/${empresaId}/imagenesVehiculo/${vehiculo.placa}/frontal`"
              :pathGet="'vehiculo/ImagenesVehiculo/' + vehiculo.id" pathPost="vehiculo/imagenVehiculo"
              :injectData="vehiculoImagenInjection" />
          </div>
          <div class="figure-zone">
            <h4 class="mt-3 mb-2">Lateral</h4>
            <DropFile :urlDir="`empresa/${empresaId}/imagenesVehiculo/${vehiculo.placa}/Lateral`"
              :pathGet="'vehiculo/ImagenesVehiculo/' + vehiculo.id" pathPost="vehiculo/imagenVehiculo"
              :injectData="vehiculoImagenInjection" />
          </div>
          <div class="figure-zone">
            <h4 class="mt-3 mb-2">Trasera</h4>
            <DropFile :urlDir="`empresa/${empresaId}/imagenesVehiculo/${vehiculo.placa}/Trasera`"
              :pathGet="'vehiculo/ImagenesVehiculo/' + vehiculo.id" pathPost="vehiculo/imagenVehiculo"
              :injectData="vehiculoImagenInjection" />
          </div>
        </div>
      </b-collapse>
    </div>
    <div>
      <div @click="isVisibleHistorial = !isVisibleHistorial" class="pointer">
        <h3 class="py-2 btn btn-outline-success px-5 my-1">Historial</h3>
      </div>
      <b-collapse id="historial" v-model="isVisibleHistorial">
        <section class="padre-container">
          <div>
            <h4 class="mt-3 mb-2" style="font-size: 1.2rem;">Frontal</h4>
            <div class="container-img position-relative ">
              <div v-for="image in frontal" :key="image.id">
                <span class="position-absolute bg-white px-2">{{ useMoment(image.fechaDeCreacion) }}</span>
                <img :src="image.urlImg" :alt="image.descripcion">
              </div>
            </div>
          </div>
          <div>
            <h4 class="mt-3 mb-2" style="font-size: 1.2rem;">Lateral</h4>
            <div class="container-img position-relative ">
              <div v-for="image in lateral" :key="image.id">
                <span class="position-absolute bg-white px-2">{{ useMoment(image.fechaDeCreacion) }}</span>
                <img :src="image.urlImg" :alt="image.descripcion">
              </div>
            </div>
          </div>
          <div>
            <h4 class="mt-3 mb-2" style="font-size: 1.2rem;">Trasera</h4>
            <div class="container-img position-relative ">
              <div v-for="image in trasera" :key="image.id">
                <span class="position-absolute bg-white px-2">{{ useMoment(image.fechaDeCreacion) }}</span>
                <img :src="image.urlImg" :alt="image.descripcion">
              </div>
            </div>
          </div>
        </section>

      </b-collapse>
    </div>
  </div>
</template>

<style scoped>
.btn btn-outline-success px-5 my-1 {
  border-bottom: .5px solid #dee2e6;
}


.padre-container {
  display: flex;
}

.padre-container>div {
  width: 32%;
  margin: 0 auto;
}

.container-img>div {
  height: 200px;

}

.container-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 968px) {

  .padre-container {
    flex-direction: column;
  }

  .padre-container>div {
    width: 100%;
  }

  .container-img>div {
    margin-bottom: .5rem;
    height: 250px;
  }
}
</style>